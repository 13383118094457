// 20240725 - support globally using mixin
Vue.mixin({
  data() {
    return {
      recharge_dialog: false,
    };
  },
  computed: {
    langCode: function () {
      return newLang.singleLangCode;
    },
    currencyCode() {
      if (companyInfo) {
        return companyInfo.currencyCode;
      }
      return "";
    },
    username() {
      if (companyInfo && companyInfo.accountInfo) {
        return companyInfo.accountInfo.username;
      }
      return "";
    },
    vipLevel() {
      if (companyInfo && companyInfo.accountInfo) {
        return companyInfo.accountInfo.vipLevel + 1;
      }
      return 1;
    },
  },
  filters: {
    numericComma: function (value) {
      if (!value) return "0.00";

      if (typeof value === "number") {
        value = value.toFixed(2);
      }

      value = value.replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return value;
    },
    maskLast2Character(value) {
      return value.replace(/..$/, "***");
    },
    maskShowLast3Character(value) {
      var length = value.length - 3;
      return value.replace(value.substring(0, length), "*".repeat(7));
    },
    uppercase(value) {
      return value.toUpperCase();
    },
    padLeadingZeroWith4Length(value) {
      if (value === "-") {
        return value;
      }
      return String(value).padStart(4, "0");
    },
    convertToDateFormat(value, format = "yyyy-MM-DD") {
      if (value != undefined) {
        return moment(value).format(format);
      }
    },
  },
  methods: {
    goBack() {
      console.log("go back");
      window.history.back();
    },
    to_customer_service() {
      console.log("to_customer_service");
      window.location.href = "/index/support/index";
    },
    to_customer_service_dialog() {
      console.log("to_customer_service_dialog");
      this.recharge_dialog = true;
    },
    to_deposit() {
      console.log("to_deposit");
      console.log("to_customer_service_dialog");
      this.recharge_dialog = true;

      // this.$confirm(
      //   "Please contact customer service to recharge",
      //   "Contact CS",
      //   {
      //     confirmButtonText: "Confirm",
      //     cancelButtonText: "Cancel",
      //     confirmButtonClass: "home_drawer_logout_btn",
      //     type: "warning",
      //   }
      // )
      //   .then(() => {
      //     window.location.href = "/index/support/index";
      //   })
      //   .catch(() => {});
    },
    to_share() {
      console.log("to_share");
      if (
        companyInfo.isEnableInviteCode &&
        companyInfo.accountInfo.isVIPEnabledInvite
      ) {
        window.location.href = "/index/my/invite";
      }
    },
    translateWord(word) {
      return newLang.getWord(word);
    },
    changeLang(langCode) {
      newLang.setLang(langCode);
      newLang.translateWordByElement($(this.$el));
    },
    queryStringByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      let regexS = "[\\?&#/#]" + name + "=([^&#]*)";
      let regex = new RegExp(regexS);

      let results = regex.exec(window.location.search);

      if (!results && window.location.hash) {
        results = regex.exec(window.location.hash);
      }

      if (results == null) return "";
      else return decodeURIComponent(results[1].replace(/\+/g, " "));
    },
  },
  mounted: function () {
    //console.log("Global Mixin lifecycle mounted hook");
    //console.log("Global Mixin translation");
    newLang.translateWordByElement($(this.$el));
  },
  updated() {
    newLang.translateWordByElement($(this.$el));
  },
});
